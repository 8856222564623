.map-section {
	@apply relative w-full h-[400px];

	.info {
		@apply z-20 absolute top-[100%] left-[50%] translate-y-[-125%] translate-x-[-50%] flex flex-col gap-[0.8rem] items-center pointer-events-none text-sm text-center uppercase tracking-widest;
		& > * {
			@apply p-[0.8rem] rounded shadow-sm;
		}
		.heading {
			@apply bg-[hsl(var(--color-primary))] text-secondary;
		}

		p {
			@apply bg-colorLayout text-colorLayoutComplementary;
		}
	}
}
