.how-section {
	&__items {
		@apply flex flex-col gap-[1.6rem] text-secondary text-center sm:grid sm:grid-cols-2 md:grid-cols-3;
		@apply lg:gap-[28px];

		.how-item {
			@apply flex flex-col items-center justify-start rounded overflow-hidden shadow-sm;
			animation: appearOnlyOpacity linear;
			animation-timeline: view();
			animation-fill-mode: both;
			animation-range-start: 0;
			animation-range-end: 100px;
			transition: all 0.3s ease-out;

			.item {
				@apply relative z-[20] h-full w-full;

				&::after {
					content: '';
					@apply absolute top-0 left-0 w-full h-full z-[-3];
					background-color: hsl(var(--color-primary));
				}
			}

			img {
				@apply z-[-1] opacity-20 object-cover;
			}
			svg,
			.text-box {
				transition: opacity 0.3s, visibility 0.3s;
			}
			svg {
				@apply mx-auto stroke-secondary w-[100px] h-[100px];
			}
			.text-box {
				@apply grid justify-items-center items-center;
				& > *::selection {
					@apply bg-colorLayout text-colorLayoutComplementary;
				}
			}
			.title {
				@apply text-2xl mb-[0.8rem] font-poppins font-semibold leading-tight;
			}

			.description {
				@apply text-base leading-snug max-w-[50ch];
			}
			.slide1,
			.slide2,
			.slide3 {
				@apply relative;
			}
			.slide1,
			.slide2 {
				@apply flex flex-col px-[16px] py-[22px];
				@apply md:px-[0.8rem];
				@apply lg:px-[28px] lg:py-[32px];
			}
			.slide2 {
				@apply absolute gap-[1.6rem] items-center text-center p-[1.6rem] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] invisible opacity-0 pointer-events-none z-[50] text-base w-full;
				transition: opacity 0.5s cubic-bezier(0.64, 0, 0.78, 0), visibility 0.5s cubic-bezier(0.64, 0, 0.78, 0);
				p {
					@apply max-w-[40ch] leading-tight;
				}
				a:link,
				a:visited {
					@apply p-[0.8rem] border-secondary border-solid border-[2px] rounded;
					transition: color 0.3s, background-color 0.3s;
				}
				@media (hover: hover) {
					a:hover,
					a:active {
						@apply text-[#000] bg-secondary;
					}
				}
			}
			//! funcionalidad
			&:last-child {
				@apply sm:col-span-2 sm:flex-row  md:col-span-1 md:flex-col;

				.item {
					.slide1,
					.slide2 {
						@apply sm:pt-[0.8rem] md:pt-[1.6rem] md:px-[0.8rem] lg:p-[1.6rem];
					}
				}
			}

			&:hover {
				transform: translateY(-2px);
				@media (hover: hover) {
					.item {
						.slide2 {
							@apply visible pointer-events-auto opacity-100;
						}

						.slide1 > *:not(img) {
							@apply invisible pointer-events-none opacity-0;
						}
					}
				}
			}
			&:not(:hover) {
				.slide2 {
					transition: none;
				}
			}

			//? NO HOVER

			@media (hover: none) {
				.item {
					@apply grid;
					grid-template-rows: 1fr 15rem;
					@media (min-width: 768px) {
						grid-template-rows: 1fr 16rem;
					}
					@media (min-width: 860px) {
						grid-template-rows: 1fr 15rem;
					}
					@media (min-width: 1024px) {
						grid-template-rows: 1fr 18rem;
					}
					@media (min-width: 1240px) {
						grid-template-rows: 1fr 15rem;
					}
					@media (min-width: 1536px) {
						grid-template-rows: 1fr 16rem;
					}
					.slide2 {
						@apply relative visible opacity-100 pointer-events-auto top-auto left-auto transform-none bg-secondary text-[#000] justify-center md:justify-between;

						p {
							@apply md:text-sm lg:text-base;
						}
						a:link,
						a:visited {
							@apply border-[#000];
						}
					}
				}
			}
		}
	}
}
