.blog-section {
	&__items {
		@apply grid justify-between gap-[48px];
		@apply md:gap-[24px];
		@apply lg:gap-[32px];

		grid-template-columns: 1fr;
		@media (min-width: 640px) {
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		}
		@media (min-width: 1024px) {
			grid-template-columns: repeat(3, 1fr);
		}

		.post {
			@apply grid gap-y-0 rounded overflow-hidden gap-[0.8rem] justify-center w-full shadow-sm;
			animation: appearOnlyOpacity linear;
			animation-timeline: view();
			animation-fill-mode: both;
			animation-range-start: 0;
			animation-range-end: 100px;
			grid-template-columns: 1fr;
			grid-template-rows: min-content 1fr;
			transition: all 0.3s ease-out;
			.image-box {
				@apply relative w-full;
				aspect-ratio: 3 / 2;
			}

			.text-box {
				@apply flex flex-col gap-[6px] items-start leading-tight p-[16px] min-h-full;
			}
			&:hover {
				transform: translateY(-2px);
			}
		}
		img {
			@apply object-cover;
		}
		.date {
			@apply block text-sm mb-[0.8rem] text-colorTextSecondary;
		}
		h3 {
			@apply text-xl mb-[0.4rem] leading-tight font-semibold line-clamp-2;
		}

		p {
			@apply leading-normal text-base text-colorTextSecondaryLight line-clamp-4;
		}
	}

	//! funcionalidad
}
