.about-section {
	@apply relative text-secondary text-center h-[70vh] grid items-center max-h-[750px];
	img {
		@apply object-cover z-[-1];
	}
	.bg-gradient {
		@apply top-0 left-0 w-full h-full absolute z-[-1];
		background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
	}
	&__items {
		@apply flex flex-col items-center gap-[2.4rem];
		animation: appearOnlyOpacity linear;
		animation-timeline: view();
		animation-fill-mode: both;
		animation-range-start: 0;
		animation-range-end: 100px;
		p {
			@apply text-base leading-normal max-w-[60ch];
		}
		a:link,
		a:visited {
			@apply flex gap-[0.4rem] items-center text-base py-[0.8rem] px-[1.6rem] hover:bg-[hsl(var(--color-primary-dark))] rounded;
			background-color: hsl(var(--color-primary));
			transition: background-color 0.2s, transform 0.2s;
			svg {
				@apply w-[3.2rem] h-[3.2rem] fill-secondary;
				transition: transform 0.2s 0.1s;
			}
		}
		a:hover,
		a:active {
			transform: translateY(2px);

			svg {
				transform: rotate(90deg);
			}
		}
	}
}
