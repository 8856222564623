.hero {
	@apply relative h-screen max-h-[1000px] min-h-[650px] grid grid-rows-1;

	img {
		@apply object-cover z-[-2];
	}
	.bg-gradient {
		@apply top-0 left-0 w-full h-full absolute z-[-1];
		background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4));
	}
	&-container {
		@apply h-full flex flex-col justify-center gap-[3.2rem] text-secondary text-center text-2xl sm:text-start;
	}

	h1 {
		@apply flex flex-col;

		.pre-heading {
			@apply mb-[0.8rem] font-light font-sans text-white-800;
		}
		.main {
			@apply text-4xl font-semibold leading-tight mb-[2.4rem] md:text-5xl sm:leading-[1.125] lg:max-w-[70%];
		}
		.sub-heading {
			@apply leading-normal max-w-[50ch];
		}
	}

	a:link,
	a:visited {
		@apply flex self-center justify-center bg-[hsl(var(--color-primary))] hover:bg-[hsl(var(--color-primary-dark))] items-center gap-[4px] p-[16px] sm:px-[2.4rem] sm:py-[1.2rem] rounded text-2xl sm:self-start;
		transition: background-color 0.2s, transform 0.2s;

		svg {
			@apply w-[3.2rem] h-[3.2rem] fill-secondary;
			transition: transform 0.2s 0.1s;
		}
	}

	a:hover,
	a:active {
		transform: translateY(2px);

		svg {
			transform: rotate(90deg);
		}
	}
}
