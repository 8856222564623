.contact-section {
	@apply relative;

	.section-heading > *::selection,
	label::selection {
		@apply bg-colorLayout text-colorLayoutComplementary;
	}
	img {
		@apply object-cover z-[-1];
	}
	.bg-gradient {
		@apply top-0 left-0 w-full h-full absolute z-[-1];
		background: linear-gradient(
			to top,
			rgba(52, 64, 178, 0.8),
			rgba(52, 64, 178, 0.8)
		);
	}

	&-container {
	}
	&__items {
	}

	&__form {
		@apply flex flex-col mx-auto gap-[0.8rem] text-secondary text-base max-w-[40ch] sm:max-w-[60ch];
		.input-container {
			@apply flex flex-col gap-[0.4rem];

			label {
				span {
					@apply pl-[4px] font-semibold;
				}
			}

			input,
			textarea {
				@apply p-[0.8rem] rounded;
				font-size: inherit;
				color: inherit;
				background-color: rgba(255, 255, 255, 0.15);
				border: 1px solid rgba(255, 255, 255, 0.8);
				transition: background-color 0.3s;
				&:hover {
					background-color: rgba(255, 255, 255, 0.2);
				}
				&:focus {
					background-color: rgba(255, 255, 255, 0.3);
				}
			}

			input::placeholder,
			textarea::placeholder {
				color: inherit;
				opacity: 0.5;
			}
		}

		button {
			@apply mt-[0.8rem] flex gap-[0.4rem] items-center self-center justify-center bg-colorBtnSecondary text-xl p-[1.2rem] rounded uppercase tracking-widest;
			transition: background-color 0.3s, color 0.3s;
			&:not([type='reset']) {
				@apply self-stretch;
				&.sended {
					@apply text-colorLayoutTertiary bg-secondary;
				}
				&.sending {
					&:hover {
						@apply bg-colorLayoutTertiary text-colorLayout;
					}
				}
			}
			&:hover {
				@apply bg-secondary text-colorLayoutTertiary;
			}
		}
	}
}
