.services-section {
	&__items {
		@apply grid justify-between gap-[1.6rem] auto-rows-fr;
		@apply lg:gap-[28px];
		grid-template-columns: 1fr;

		.service-item {
			@apply flex flex-grow relative rounded overflow-hidden shadow-sm w-full;
			animation: appearOnlyOpacity linear;
			animation-timeline: view();
			animation-fill-mode: both;
			animation-range-start: 0;
			animation-range-end: 100px;
			transition: all 0.3s ease-out;

			img {
				@apply object-cover z-[-1];
			}
			.bg-gradient {
				@apply top-0 left-0 w-full h-full absolute z-[-1] transition-all;
				background: linear-gradient(to top, rgba(0, 0, 0, 0.89), rgba(0, 0, 0, 0.6));
				transition: opacity 0.3s;
			}
			.text-box {
				@apply py-[24px] px-[16px] grow grid gap-y-[16px] grid-rows-[min-content,_min-content,_1fr] items-start text-secondary;
				@apply sm:px-[24px] sm:py-[32px];
				@apply lg:px-[32px] lg:py-[64px];

				& .link-container {
					@apply flex items-end min-h-full;
				}

				h3 {
					@apply text-2xl font-semibold leading-tight;
				}
				p {
					@apply text-base max-w-[50ch] leading-snug line-clamp-4;
				}
				button {
					@apply bg-primary text-secondary p-[1.2rem] sm:px-[1.2rem] sm:py-[0.8rem] rounded text-base cursor-pointer;
				}
			}

			&:hover {
				transform: translateY(-2px);

				.bg-gradient {
					opacity: 0.78;
				}
			}
		}
	}
}

.service-modal {
	@apply flex flex-col gap-[0.8rem] px-[1.6rem] pt-[0.8rem] pb-[1.6rem] rounded-md z-[600] box-border overflow-hidden bg-colorLayoutComplementary text-colorLayout shadow-md sm:grid sm:grid-cols-2 sm:px-[2.4rem] sm:pt-[1.6rem] sm:pb-[2.4rem] sm:gap-[2.4rem];
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 3.2rem);
	max-width: 400px;

	@media (min-width: 640px) {
		max-width: 600px;
	}
	@media (min-width: 768px) {
		max-width: 80rem;
	}

	picture {
		@apply w-full relative sm:min-h-full rounded overflow-hidden shadow-md;
		aspect-ratio: 3 / 2;
		img {
			@apply w-full h-full object-cover;
			aspect-ratio: 3 / 2;
		}
	}
	.modal-text-box {
		@apply flex flex-col gap-[8px];
	}

	h4 {
		@apply text-xl font-semibold leading-tight;
	}

	p {
		@apply text-base leading-tight mb-[0.8rem];
	}

	a:link,
	a:visited {
		@apply inline-block text-base p-[16px] bg-primary text-white mb-[1.6rem] w-max rounded;
		@apply hover:bg-white hover:text-eerieBlack;
		@apply sm:mb-0;
		transition: background-color 0.3s, color 0.2s;
	}
	.close-icon {
		@apply flex items-center justify-center w-[4rem] h-[4rem] p-[0.8rem] rounded-full self-end bg-[#f03e3e];
		@apply hover:bg-[#e03131];
		@apply sm:col-span-2 sm:justify-self-end;
		transition: background-color 0.3s;
		svg {
			@apply h-[3.2rem] w-[3.2rem] fill-white;
		}
	}

	.close-text {
		@apply py-[0.8rem] px-[1.2rem] text-base rounded bg-[#f03e3e] text-white;
		@apply hover:bg-[#e03131];
		@apply sm:col-span-2;

		transition: background-color 0.3s, color 0.3s;
	}
}
