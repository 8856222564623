.features-section {
	&__items {
		@apply flex flex-col flex-grow items-center gap-[48px];
		@apply sm:grid sm:grid-cols-3 sm:gap-[24px];

		.feature-item {
			@apply flex grow flex-col items-center text-center gap-[12px] h-full w-full shadow-sm p-[8px] py-[24px] rounded;
			@apply md:px-[16px];
			@apply lg:px-[24px];

			animation: appearOnlyOpacity linear;
			animation-timeline: view();
			animation-fill-mode: both;
			animation-range-start: 0;
			animation-range-end: 100px;
			transition: all 0.3s ease-out;

			&:hover {
				transform: translateY(-2px);
			}
			.icon-box {
				@apply bg-[hsl(var(--color-primary))] max-w-max rounded-full p-[1.6rem];
				svg {
					@apply mx-auto w-[10rem] h-[10rem] fill-secondary;
				}
			}

			.text-box {
				@apply pointer-events-none;
				h3 {
					@apply text-2xl font-semibold mb-[8px];
					@apply sm:text-[24px] mb-[12px];
					@apply lg:text-[32px];
				}
				p {
					@apply text-base max-w-[30ch] leading-snug;
				}
			}
		}
	}
}
